/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
    //To check if an element's content overflows
    $.fn.overflown = function () {
        var e = this[0];
        return e.scrollHeight > e.clientHeight || e.scrollWidth > e.clientWidth;
    };

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                $('#menu-banner-header ul li').hover(function () {
                    $(this).css('border-bottom', '3px solid #4a89dc');
                    var $menuimg = $(this).children('a').children('.menuimg');
                    $menuimg.css('background-image', 'url(' + $menuimg.data('link-hover') + ')');
                }, function () {
                    if (!$(this).hasClass('active-page')) {
                        $(this).css('border-bottom', 'none');
                        var $menuimg = $(this).children('a').children('.menuimg');
                        $menuimg.css('background-image', 'url(' + $menuimg.data('link') + ')');
                    }

                });

                $('#menu-banner-header ul li').click(function () {
                    window.location.href = $(this).children('a').attr('href');
                });

                $(".youtube").YouTubeModal({autoplay: 0, width: 640, height: 370});

                $(".filter_select").change(function () {
                    var query = "?";
                    $(".filter_select").each(function () {
                        if ($(this).val() !== "") {
                            query += $(this).attr("query-arg") + "=" + $(this).val() + "&";
                        }
                    });
                    window.location.href = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
                });
                // browser window scroll (in pixels) after which the "back to top" link is shown
                var offset = 300,
                    //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
                    offset_opacity = 1200,
                    //duration of the top scrolling animation (in ms)
                    scroll_top_duration = 700,
                    //grab the "back to top" link
                    $back_to_top = $('.cd-top');

                //hide or show the "back to top" link
                $(window).scroll(function () {
                    //( $(this).scrollTop() > offset ) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
                    if ($(this).scrollTop() > offset) {
                        $back_to_top.addClass('cd-is-visible');
                    } else {
                        $back_to_top.removeClass('cd-is-visible cd-fade-out');
                    }
                    if ($(this).scrollTop() > offset_opacity) {
                        $back_to_top.addClass('cd-fade-out');
                    }
                });

                //smooth scroll to top
                $back_to_top.on('click', function (event) {
                    event.preventDefault();
                    $('body,html').animate({
                            scrollTop: 0,
                        }, scroll_top_duration
                    );
                });

                $('#myModal').on('show.bs.modal', function (event) {
                    var button = $(event.relatedTarget); // Button that triggered the modal
                    var title = button.data('title'); // Extract info from data-* attributes
                    var img = button.data('img'); // Extract info from data-* attributes
                    var content = button.prev('.update-content');
                    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
                    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
                    var modal = $(this);
                    modal.find('.modal-title').text(title);
                    // modal.find('.modal-body').html(img);
                    modal.find('.modal-body').html(img + content.html());
                });

                //if ( $('.div-update').length ) {
                function readmore_update() {
                    $(".div-update").each(function (index) {
                        var value = $(this).children('.update-content').html();//document.getElementById('update-content').offsetHeight

                        if (!value.endsWith("...")) {

                            $(this).children('.btn-read-more').hide();

                        }


                    });
                }

                readmore_update();
                $(window).scroll(function () {
                    var sticky = $('#banner-menu'),
                        scroll = $(window).scrollTop();

                    if (scroll >= 100) {
                        sticky.addClass('fixed');
                    }
                    else {
                        sticky.removeClass('fixed');
                    }
                });


                $('.video-title').textfill({
                    //'minFontPixels':'6',
                    maxFontPixels: 18,
                });

                function resizeDiv() {
                    var max_height = 0;
                    $('.container-bg-color').height('auto');

                    $('.container-bg-color').each(function () {
                        var height = $(this).height();


                        if (height > max_height) {
                            max_height = height;

                            $('.container-bg-color').each(function () {
                                var height_aux = $(this).height();


                                if (height_aux < max_height) {
                                    $(this).height(max_height);
                                }

                            });

                        }

                    });
                }

                $(window).load(function () {
                    resizeDiv();
                    //readmore_update();
                    $('select').selectric();
                });
                $(window).ready(function () {

                    $('select').selectric();
                });
                $(window).resize(function () {
                    resizeDiv();
                });

                $('select').selectric();

                if (window.location.search !== '') {
                    $('#menu-banner-header ul li a').each(function () {
                        $(this).attr('href', $(this).attr('href') + window.location.search);

                    });
                    //$('#menu-banner-header ul li a').attr('href', $('#menu-banner-header ul li a').attr('href')+window.location.search);
                }
                /*$('.update-content .maxheight').each(function(){
                 if($(this).overflown()){
                 $(this).append('<div class="textfader"/>');
                 }
                 });*/

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                var set_timeout = null;
                var html_old = '';
                $('.product_search').keyup(function () {

                    if ('' === html_old) {
                        html_old = $('#container-updates').html();
                    }
                    clearTimeout(set_timeout);

                    if ('' === $('.product_search').val()) {

                        $('#container-updates').html(html_old);
                        $("#clear_product_search").removeClass("show_search");
                    } else {

                        $("#clear_product_search").addClass("show_search");
                        set_timeout = setTimeout(function () {
                            $.post(
                                '/wp-admin/admin-ajax.php',
                                {
                                    'action': 'product_search',
                                    'data': $('.product_search').val(),
                                },
                                function (response) {
                                    response = JSON.parse(response);
                                    if (response.success) {
                                        $('#container-updates').html(response.html);
                                    }
                                    //alert('The server responded: ' + response.query );
                                }
                            );

                        }, 500);
                    }


                });

                $('.video_search').keyup(function () {

                    if ('' === html_old) {
                        html_old = $('#videos-container').html();
                    }
                    clearTimeout(set_timeout);

                    if ('' === $('.video_search').val()) {

                        $('#videos-container').html(html_old);
                        $("#clear_product_search").removeClass("show_search");
                    } else {

                        $("#clear_product_search").addClass("show_search");
                        set_timeout = setTimeout(function () {
                            $.post(
                                '/wp-admin/admin-ajax.php',
                                {
                                    'action': 'video_search',
                                    'data': $('.video_search').val(),
                                },
                                function (response) {
                                    response = JSON.parse(response);
                                    if (response.success) {
                                        $('#videos-container').html(response.html);
                                        $('.video-title').textfill({
                                            //'minFontPixels':'6',
                                            maxFontPixels: 18,
                                        });
                                        $(document).find('.youtube').YouTubeModal({autoplay: 0, width: 640, height: 370});

                                    }

                                }
                            );

                        }, 500);
                    }

                });

                $('#clear_product_search').click(function () {
                    if ('' !== $('.product_search').val()) {
                        $('.product_search').val('');
                        $('#container-updates').html(html_old);
                        $("#clear_product_search").removeClass("show_search");
                    }

                    if ('' !== $('.video_search').val()) {
                        $('.video_search').val('');
                        $('#videos-container').html(html_old);
                        $("#clear_product_search").removeClass("show_search");
                    }


                });


            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
    $(".youtube").YouTubeModal({autoplay: 0, width: 640, height: 370});
})(jQuery); // Fully reference jQuery after this point.